import React from "react";
import tw, { styled, css } from "twin.macro";

export const Item = styled.li`
  ${tw`relative w-full my-2 text-base text-white opacity-0 sm:text-lg z-highest`}
  a {
    ${tw`flex items-center justify-start hover:text-primary`}

    strong {
      ${tw`mr-2 text-sm tracking-widest text-primary`}
    }
  }
  & > ul {
    ${tw` max-h-0`}
  }
`;

export const ExpandButton = styled.button`
  ${tw`absolute right-0 flex items-center justify-center text-base text-white transition-all duration-500 ease-in-out bg-primary hover:bg-hover`}
  width: 25px;
  height: 25px;
  top: 2px;
  border-radius: 50%;

  &.active {
    ${tw`bg-hover`}
    transform: rotate(180deg);
  }

  &:active,
  &:focus {
    outline: none;
  }
`;

export const Children = styled.ul`
  ${tw`relative w-full pl-4 overflow-hidden transition-all duration-500 ease-in-out border-l-default border-primary`}
  & > li {
    ${tw`opacity-100`}
  }
`;

export const List = styled.ul`
  ${tw`flex flex-col items-start justify-start -my-1 text-xl font-bold`}
  &.active {
    li {
      ${tw`opacity-100!`}
    }
  }
`;

export const Header = styled.header(({ visible }) => [
  tw`fixed top-0 left-0 items-center justify-between hidden w-full px-6 py-4 transition-all duration-500 ease-in-out shadow-lg bg-background z-excessive`,
  css`
    @media (max-width: 1280px) {
      ${tw`flex`}
    }

    .logo {
      width: 100px;
    }
  `,
  visible === false && tw`opacity-0`,
]);

const OpenBtnContainer = styled.button`
  ${tw`px-2 -my-1`}
  width: 50px;
  height: 50px;
`;
const BtnLine = styled.div`
  ${tw`w-full bg-primary`}
  margin: 0.3rem 0;
  height: 2px;

  &:nth-child(2) {
    transform: translateX(0.4rem);
  }
`;

export const OpenButton = (props) => (
  <OpenBtnContainer {...props}>
    <BtnLine />
    <BtnLine />
    <BtnLine />
  </OpenBtnContainer>
);

export const Nav = styled.nav(() => [
  tw`fixed top-0 left-0 w-full p-8 overflow-hidden transition-all duration-500 ease-in-out pointer-events-none z-highest`,
  css`
    &.active {
      ${tw`pointer-events-auto`}
    }
    max-height: calc(100vh - 6rem - 55px);

    @media (max-width: 640px) {
      ${tw`px-4`}
    }
  `,
]);

export const CloseBtn = styled.button`
  ${tw`fixed flex items-center justify-center text-4xl text-white transition-all duration-300 ease-in-out z-highest bg-primary font-mont hover:bg-hover`}
  bottom: -55px;
  left: calc(50% - 25px);
  border-radius: 50%;
  width: 50px;
  height: 50px;

  &.active {
    bottom: 4rem;
  }
`;

export const MenuBackground = styled.div`
  ${tw`absolute top-0 left-0 w-full h-full`}
  transform: translateY(-100%);
  z-index: 999;
`;

export const Overlay = styled.div`
  ${tw`fixed top-0 left-0 w-full h-full transition-all duration-500 ease-in-out bg-white pointer-events-none`}
  opacity: 0;
  z-index: 1001;

  &.active {
    ${tw`pointer-events-auto`}
    opacity: 0.8;
  }
`;
