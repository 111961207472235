import React from "react";
import tw, { styled } from "twin.macro";
import Highlight from "@/Highlight";
import Heading from "@/Heading";
import Content from "@/Content";

const Styles = () => [tw`flex flex-col items-start justify-start w-full mb-16`];

const Title = ({
  supertext,
  title,
  white,
  description,
  h1,
  hc,
  header,
  ...rest
}) => {
  const Component = header ? styled.header(Styles) : styled.div(Styles);
  return (
    <Component {...rest}>
      <Highlight tw="mb-2 text-left">{supertext}</Highlight>
      <Heading h1={h1} hc={hc} white={white} tw="text-left">
        {title}
      </Heading>
      {description && <Content>{description}</Content>}
    </Component>
  );
};

export default Title;
