import React from "react";
import tw, { styled } from "twin.macro";

const HighlightStyles = styled.p`
  ${tw`text-sm md:text-base flex items-center justify-center before:(content[''] mr-4 h-2px bg-primary) text-primary font-bold font-mont`}
  &:before {
    @media (max-width: 640px) {
      width: 40px;
    }
    width: 100px;
  }
`;

const Highlight = ({ ...rest }) => <HighlightStyles {...rest} />;

export default Highlight;
