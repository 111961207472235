import React, { createRef } from "react";
import tw, { styled } from "twin.macro";
import Input from "@/Input";
import Button from "@/Button";

const FormStyles = tw.form`flex flex-wrap w-full -mx-4 -my-8 items-center justify-center`;

const Row = styled.div(({ width }) => [
  tw`flex flex-col items-start justify-start w-full py-4 text-white xl:px-4`,
  width === "third" && tw`xl:w-1/3`,
]);

const Message = styled.p(({ active, error }) => [
  tw`w-full mt-8 font-bold text-center text-white transition-all duration-500 ease-in-out transform translate-y-full opacity-0`,
  active && tw`translate-y-0 opacity-100`,
  error && tw`text-red-600`,
]);

class Form extends React.Component {
  state = {
    inputs: [],
    error: false,
    message: "",
  };
  constructor(props) {
    super(props);
    this.formRef = createRef(null);
    this.state = {
      inputs: props.form.map(() => ({
        ref: createRef(null),
        valid: null,
        error: "No errors.",
      })),
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  validate(input, strict = false) {
    if (input.value.trim === "" && strict === false) return null;
    else return input.checkValidity();
  }

  validateAll() {
    let allValid = true;
    const inputs = this.state.inputs.map((input, i) => {
      const { valid, error, ...rest } = input;
      const inputElement = input.ref.current.querySelector("input,textarea");
      let validity = this.validate(inputElement, true);
      allValid = validity && allValid;
      const errorMsg = validity ? error : inputElement.validationMessage;
      return {
        error: errorMsg,
        valid: validity,
        ...rest,
      };
    });
    this.setState({ inputs: inputs });
    return allValid;
  }

  async submit() {
    const form = this.formRef.current;
    try {
      const data = new FormData(form);
      const response = await fetch(form.action + ".json", {
        method: form.method,
        body: data,
        headers: new Headers({
          Accept: "application/json",
        }),
      });
      if (response.ok) {
        this.setState({
          message:
            "Thank you for submitting our form! Someone from our office will be with you shortly.",
        });
        this.afterSubmit();
      } else
        throw `Basin returned a non-200 status code. Returned: ${response.status}`;
    } catch (e) {
      this.setState({
        error: true,
        message: "There was an error submitting your form. Please try again.",
      });
      console.error("Erorr submitting form.", e);
    }
    form.reset();
  }

  afterSubmit() {
    this.setState({
      inputs: this.state.inputs.map((input) => {
        input.valid = null;
        return input;
      }),
    });
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.validateAll()) this.submit();
  }

  onChange(e) {
    const input = e.target;
    const index = parseInt(input.dataset.index);
    const inputs = this.state.inputs;
    const entry = inputs[index];
    const valid = this.validate(input);
    entry["valid"] = valid;
    entry["error"] = valid ? entry["error"] : input.validationMessage;
    inputs[index] = entry;
    this.setState({ inputs: inputs });
  }

  render() {
    const { form, submit, action } = this.props;
    return (
      <FormStyles ref={this.formRef} action={action} method="POST">
        {form.map((row, i) => (
          <Row key={i} width={row.width} ref={this.state.inputs[i].ref}>
            <Input
              {...row}
              data-index={i}
              valid={this.state.inputs[i].valid}
              onChange={this.onChange}
              error={this.state.inputs[i].error}
            />
          </Row>
        ))}
        <Button tw="md:mt-8" onClick={this.onSubmit}>
          {submit}
        </Button>
        <Message error={this.state.error} active={this.state.message}>
          {this.state.message}
        </Message>
      </FormStyles>
    );
  }
}

export default Form;
