import React, { useState } from "react";
import { graphql, StaticQuery } from "gatsby";
import { flatMenuToHierarchical } from "#/functions";
import useScrollPosition from "Hooks/useScrollPosition";
import { Nav, List, Children, TopItem, ChildItem, ContactBtn } from "./styles";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const DesktopNavigation = ({ visible }) => {
  // const [sticky, setSticky] = useState(false);
  // useScrollPosition(
  //   ({ currPos }) => {
  //     const shouldBeSticky = currPos.y < 0;
  //     if (sticky !== shouldBeSticky) setSticky(shouldBeSticky);
  //   },
  //   [sticky],
  //   false,
  //   false,
  //   100
  // );

  return (
    <StaticQuery
      query={graphql`
        query {
          mainMenu: wpMenu(locations: { eq: MAIN_MENU }) {
            menuItems {
              ...MenuItems
            }
          }
          wp {
            esquireAcf {
              siteSettings {
                logo {
                  ...Image
                }
              }
            }
          }
        }

        fragment MenuItems on WpMenuToMenuItemConnection {
          nodes {
            url: path
            title: label
            key: id
            parentId
          }
        }
      `}
      render={(data) => {
        const menu = flatMenuToHierarchical(data.mainMenu?.menuItems.nodes);
        const { logo } = data.wp.esquireAcf.siteSettings;
        const listItems = (items, child = false) =>
          items
            .filter(({ title }) => title != "Contact")
            .map((item, index) => {
              const ItemComponent = child ? ChildItem : TopItem;
              return (
                <ItemComponent key={index}>
                  <Link to={item.url}>{item.title}</Link>
                  {item.children && (
                    <Children className="children">
                      {listItems(item.children, true)}
                    </Children>
                  )}
                </ItemComponent>
              );
            });
        return (
          <Nav visible={visible}>
            <Link to="/">
              <GatsbyImage
                image={getImage(logo.localFile)}
                alt={logo.altText}
                className="logo"
              />
            </Link>
            <List>
              {listItems(menu)}
              <ContactBtn>
                <Link to="/contact/">Contact</Link>
              </ContactBtn>
            </List>
          </Nav>
        );
      }}
    />
  );
};

export default DesktopNavigation;
