import tw, { styled, css } from "twin.macro";

export const Section = styled.footer`
  ${tw`relative flex flex-col items-center justify-center w-full px-4 py-16 text-center text-white md:py-32 xl:px-24 sm:px-8 bg-background`}
`;

export const Copyright = styled.p`
  ${tw`absolute flex items-center justify-center font-bold text-white`}
  bottom: 1.5rem;
  right: 1.5rem;

  @media (max-width: 1024px) {
    ${tw`w-full`}
    right: 0;
  }

  span {
    ${tw`mx-3`}
  }
`;

export const Menus = styled.div`
  ${tw`flex flex-col items-center justify-between w-full mt-32 mb-6 lg:(flex-row my-48 items-start)`}

  .logo {
    max-width: 200px;

    @media (max-width: 1024px) {
      ${tw`mb-4`}
    }
  }
`;

export const Menu = styled.ul`
  ${tw`flex flex-col items-center justify-start py-4 pl-0 lg:py-0`}
`;

export const MenuTitle = tw.li`font-bold text-primary text-xl`;
