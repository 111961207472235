const { hostname, wordpressUrl, gtmCode } = require("./util/constants");
module.exports = {
  siteMetadata: {
    siteUrl: hostname,
    title: "Esquire Digital Static",
  },
  plugins: [
    {
      resolve: "gatsby-source-wordpress",
      options: {
        url: `${wordpressUrl}/graphql`,
        type: {
          MediaItem: {
            localFile: {
              maxFileSizeBytes: 15728640, // ~15Mb
            },
          },
        },
      },
    },
    "gatsby-plugin-styled-components",
    "gatsby-plugin-react-helmet",
    "gatsby-plugin-image",
    {
      resolve: "gatsby-plugin-google-tagmanager",
      options: {
        id: gtmCode,
      },
    },
    {
      resolve: "gatsby-plugin-sitemap",
      options: {
        query: `
          {
            site {
              siteMetadata {
                siteUrl
              }
            }
            allSitePage {
              nodes {
                path
              }
            }
            allWpContentNode(filter: {nodeType: {in: ["Post", "Page"]}}) {
              nodes {
                ... on WpPost {
                  uri
                  modifiedGmt
                }
                ... on WpPage {
                  uri
                  modifiedGmt
                }
              }
            }
          }
        `,
        output: "/",
        resolveSiteUrl: ({ site }) => site.siteMetadata.siteUrl,
        resolvePages: ({
          allSitePage: { nodes: allPages },
          allWpContentNode: { nodes: allWpNodes },
        }) => {
          const wpNodeMap = allWpNodes.reduce((acc, node) => {
            const { uri } = node;
            acc[uri] = node;

            return acc;
          }, {});

          return allPages.map((page) => ({ ...page, ...wpNodeMap[page.path] }));
        },
        serialize: ({ path, modifiedGmt }) => ({
          url: path,
          lastmod: modifiedGmt,
        }),
      },
    },
    {
      resolve: "gatsby-plugin-manifest",
      options: {
        icon: "src/images/icon.png",
      },
    },
    "gatsby-plugin-sharp",
    "gatsby-transformer-sharp",
    {
      resolve: "gatsby-source-filesystem",
      options: {
        name: "images",
        path: "./src/images/",
      },
      __key: "images",
    },
    {
      resolve: "gatsby-plugin-robots-txt",
      options: {
        sitemap: `${hostname}/sitemap-index.xml`,
        disallow: ["/wp-admin", "/"],
      },
    },
    {
      resolve: `gatsby-plugin-webfonts`,
      options: {
        fonts: {
          google: [
            {
              family: "Montserrat",
              variants: ["300", "400", "700"],
            },
          ],
        },
      },
    },
    "gatsby-plugin-netlify", // make sure to keep this last in the array
  ],
};
