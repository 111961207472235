import React from "react";
import tw, { styled, css } from "twin.macro";

const Styles = ({ dark }) => [
  tw`px-24 py-4 text-xs sm:text-sm font-bold border-2 border-solid border-transparent tracking-widest text-center text-white uppercase transition-colors duration-500 ease-in-out bg-primary hover:(bg-transparent border-primary)`,
  css`
    border-radius: 60px;
  `,
  dark && tw`bg-palette-3 hover:border-palette-3`,
];

const Button = ({ ...rest }) => {
  const Component = styled.button(Styles);
  return <Component {...rest} />;
};

export default Button;
