import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { flatMenuToHierarchical } from "#/functions";
import {
  Header,
  OpenButton,
  Nav,
  Item,
  List,
  Children,
  ExpandButton,
  CloseBtn,
  MenuBackground,
  Overlay,
} from "./styles";
import { Link } from "gatsby";
import anime from "animejs";
import tw from "twin.macro";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

class MobileNavigation extends React.Component {
  state = {
    isOpen: false,
    inTransition: false,
  };

  constructor(props) {
    super(props);
    this.menuRef = React.createRef();
    this.blueRef = React.createRef();
    this.darkRef = React.createRef();
    this.buttonRef = React.createRef();
    this.navRef = React.createRef();
  }

  closeAllLists = () => {
    this.menuRef.current.querySelectorAll("ul").forEach((el) => {
      el.style.maxHeight = null;
    });
    this.menuRef.current.querySelectorAll("button").forEach((el) => {
      el.innerText = "+";
      el.classList.remove("active");
    });
  };

  toggleLists = (list, addedHeight = 0) => {
    const parentList = list
      .closest("li")
      .parentElement.closest("li")
      ?.querySelector("ul");
    const opening =
      list.style.maxHeight === null || list.style.maxHeight === "";
    const height = list.scrollHeight;
    if (parentList && opening)
      this.toggleLists(parentList, addedHeight + height);
    if (opening || addedHeight)
      list.style.maxHeight = `${height + addedHeight}px`;
    else list.style.maxHeight = null;
  };

  toggleChildren = (e) => {
    const button = e.target.closest("button");
    const childrenList = e.target.closest("li").querySelector("ul");
    button.classList.toggle("active");
    childrenList.classList.toggle("active");
    button.innerText = button.classList.contains("active") ? "-" : "+";

    const self = button.closest("li");
    let siblings = self.parentElement.closest("ul").querySelectorAll("li");
    siblings = Array.from(siblings).filter((el) => el !== self);

    siblings.forEach((sibling) => {
      sibling
        .querySelectorAll("ul")
        .forEach((list) => (list.style.maxHeight = null));
      sibling.querySelectorAll("button").forEach((button) => {
        button.innerText = "+";
        button.classList.remove("active");
      });
    });

    this.toggleLists(childrenList);
  };

  toggleScroll = () => {
    if (document.body.classList.contains("locked")) {
      document.body.maxHeight = null;
      document.body.style.overflowY = null;
    } else {
      document.body.maxHeight = "100vh";
      document.body.style.overflowY = "hidden";
    }
    document.body.classList.toggle("locked");
  };

  openMenu = () => {
    if (!this.state.inTransition) {
      this.setState({ inTransition: true, isOpen: true });
      this.toggleScroll();
      const timeline = anime.timeline({
        easing: "easeInOutQuad",
        duration: 500,
        delay: anime.stagger(50),
      });
      timeline
        .add({
          targets: [this.blueRef.current, this.darkRef.current],
          translateY: ["-100%", "0"],
          easing: "easeInOutQuad",
        })
        .add({
          targets: "#mobile-menu > li",
          translateY: ["4rem", "0"],
          opacity: ["0%", "100%"],
          duration: 500,
          delay: anime.stagger(50),
          complete: () => {
            this.setState({ inTransition: false });
            this.navRef.current.style.overflowY = "auto";
            this.navRef.current.style.backgroundColor = "#182f3b";
          },
        });
    }
  };

  closeMenu = () => {
    if (!this.state.inTransition) {
      this.setState({ isOpen: false, inTransition: true });
      this.toggleScroll();
      this.closeAllLists();
      this.navRef.current.style.backgroundColor = null;
      const timeline = anime.timeline({
        easing: "easeInOutQuad",
        duration: 500,
      });
      timeline
        .add({
          targets: "#mobile-menu > li",
          translateY: ["0", "-4rem"],
          opacity: ["100%", "0%"],
          duration: 500,
          delay: anime.stagger(50),
        })
        .add({
          targets: [this.darkRef.current, this.blueRef.current],
          translateY: ["-100%"],
          easing: "easeInOutQuad",
          duration: 500,
          delay: anime.stagger(50),
          complete: () => {
            this.setState({ inTransition: false });
            this.navRef.current.style.overflowY = "hidden";
          },
        });
    }
  };

  render() {
    const { visible } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query {
            mainMenu: wpMenu(locations: { eq: MAIN_MENU }) {
              menuItems {
                ...MenuItems
              }
            }
            wp {
              esquireAcf {
                siteSettings {
                  logo {
                    ...Image
                  }
                }
              }
            }
          }
        `}
        render={(data) => {
          const menu = flatMenuToHierarchical(data.mainMenu?.menuItems.nodes);
          const { logo } = data.wp.esquireAcf.siteSettings;
          const listItems = (items, child = false) =>
            items.map((item, index) => {
              return (
                <Item key={index}>
                  <Link
                    to={item.url}
                    onClick={(e) => {
                      if (item.url && item.url.slice(-1) !== "#")
                        this.toggleScroll();
                    }}
                  >
                    {!child && (
                      <strong>
                        {index < 10 ? `0${index + 1}/` : `${index + 1}/`}
                      </strong>
                    )}
                    {item.title}
                  </Link>
                  {item.children.length > 0 && (
                    <>
                      <ExpandButton onClick={this.toggleChildren}>
                        +
                      </ExpandButton>
                      <Children>{listItems(item.children, true)}</Children>
                    </>
                  )}
                </Item>
              );
            });
          return (
            <>
              <Header visible={visible}>
                <Link to="/">
                  <GatsbyImage
                    image={getImage(logo.localFile)}
                    alt={logo.altText}
                    className="logo"
                  />
                </Link>
                <OpenButton onClick={this.openMenu} />
              </Header>
              <Nav ref={this.navRef} className={this.state.isOpen && "active"}>
                <List ref={this.menuRef} id="mobile-menu">
                  {listItems(menu)}
                </List>
                <MenuBackground ref={this.blueRef} tw="bg-primary" />
                <MenuBackground ref={this.darkRef} tw="bg-dark-blue" />
              </Nav>
              <Overlay
                onClick={this.closeMenu}
                className={this.state.isOpen && "active"}
              />
              <CloseBtn
                className={this.state.isOpen && "active"}
                onClick={this.closeMenu}
              >
                &times;
              </CloseBtn>
            </>
          );
        }}
      />
    );
  }
}

export default MobileNavigation;
