import React from "react";
import tw, { styled } from "twin.macro";
import { Link } from "gatsby";

const UnderlinedLinkStyles = tw(
  Link
)`text-lg relative after:(content[''] flex absolute h-2px bottom-0 left-0 w-0 bg-primary transition-all duration-500 ease-in-out) hover:after:w-full`;

const UnderlinedLink = ({ ...rest }) => <UnderlinedLinkStyles {...rest} />;

export default UnderlinedLink;
