import React from "react";
import tw, { styled, css } from "twin.macro";

const Styles = ({ hc, white }) => [
  tw`text-3xl font-bold leading-none md:text-5xl text-gray font-mont`,
  hc && tw`text-text`,
  white && tw`text-white`,
];

const Heading = ({ h1, ...rest }) => {
  const Component = h1 === true ? styled.h1(Styles) : styled.h2(Styles);
  return <Component {...rest} h1={h1} />;
};

export default Heading;
