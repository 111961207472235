import React from 'react'
import tw, { styled } from 'twin.macro'

const ImageStyles = tw.p`block`

const Image = ({ prop, ...rest}) => (
  <ImageStyles { ...rest }>Edit me</ImageStyles>
)

export default Image
