import React from "react";
import tw, { styled, css } from "twin.macro";
import { Link } from "gatsby";
import Image from "@/Image";

export const Children = styled.ul`
  ${tw`absolute flex flex-col text-white bg-black`}
`;
export const Nav = styled.nav(({ opaque, visible }) => [
  tw`absolute top-0 left-0 items-start justify-between hidden w-full px-12 py-6 text-white transition-all duration-500 ease-in-out z-excessive`,
  css`
    @media (min-width: 1281px) {
      ${tw`flex`}

      .logo {
        ${tw`transition-all duration-500 ease-in-out`}
        width: 175px;
      }
    }
  `,
  opaque && tw`text-black bg-white shadow-lg `,
  opaque &&
    css`
      .logo {
        width: 120px;
      }
    `,
  visible === false && tw`opacity-0`,
]);
export const List = tw.ul`flex items-center justify-center mt-2 -mx-4 list-none`;
export const TopItem = styled.li(({ highContrast }) => [
  tw`relative font-bold`,
  highContrast && tw`text-white`,
  css`
    a {
      ${tw`relative flex px-4 py-1 overflow-hidden transition-all duration-500 ease-in-out`}

      &:before {
        ${tw`absolute top-0 left-0 w-full h-full transition-all duration-500 ease-in-out bg-primary`}
        transform: translateX(-105%);
        z-index: -1;
        content: "";
      }
      &:hover {
        ${tw`text-white`}
        &:before {
          transform: none;
        }
      }
    }

    & > ul {
      ${tw`right-0 hidden`}
      top: 100%;
    }

    &:hover {
      & > .children {
        ${tw`flex`}
      }
    }
  `,
]);
export const ChildItem = styled.li`
  ${tw`relative flex w-full font-bold`}

  a {
    ${tw`flex w-full py-1 whitespace-nowrap`}
  }

  & > .children {
    ${tw`top-0 hidden`}
    right: 100%;
  }

  &:hover {
    ${tw`bg-primary`}

    & > .children {
      ${tw`flex`}
    }
  }
`;
export const ContactBtn = styled.li(() => [
  tw`font-bold text-white transition-all duration-500 ease-in-out border-2 bg-primary border-primary`,
  css`
    border-radius: 25px;

    a {
      ${tw`block px-8 py-2`}
    }

    &:hover {
      ${tw`bg-transparent text-primary`}
    }
  `,
]);

export const Logo = (props) => (
  <Link to="/">
    <Image {...props} />
  </Link>
);
