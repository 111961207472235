import React from "react";
import Helmet from "react-helmet";
import SEO from "@/SEO";
import GlobalStyles from "@/GlobalStyles";
import DesktopNavigation from "Partials/DesktopNavigation";
import MobileNavigation from "Partials/MobileNavigation";
import { ModalProvider } from "@/ModalContext";
import Modal from "@/Modal";
import Footer from "Partials/Footer";

/*
 *  This is a layout component. Add partials that should appear on
 *  every page using this layout, such as footers and navigation.
 *  Remove the Modal component if it is never used, it will be included
 *  in the bundle and slow pagespeed scores.
 */
const Layout = ({ children, seo, location, noHeader }) => {
  if (!location)
    throw `Required prop "location" not present. Must have href information for SEO.`;
  if (!seo)
    throw `Require prop "seo" not present for page ${location.pathname}.`;
  return (
    <ModalProvider>
      <Helmet htmlAttributes={{ lang: "en-US" }} />
      <GlobalStyles />
      <Modal />
      {!noHeader && (
        <>
          <DesktopNavigation />
          <MobileNavigation />
        </>
      )}
      {seo && <SEO seo={seo} location={location} />}
      <main>{children}</main>
      <Footer />
    </ModalProvider>
  );
};

export default Layout;
