import React from "react";
import tw, { styled } from "twin.macro";
import { createGlobalStyle } from "styled-components";
import { GlobalStyles as TailwindStyles } from "twin.macro";

const CustomStyles = createGlobalStyle`
  body {
    ${tw`font-mont`}
  }

  button {
    ${tw`border-none cursor-pointer`}
  }
`;
const GlobalStyles = ({ prop, ...rest }) => (
  <>
    <TailwindStyles />
    <CustomStyles />
  </>
);

export default GlobalStyles;
