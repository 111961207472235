import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Section, Copyright, Menus, Menu, MenuTitle } from "./styles";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Title from "@/Title";
import Form from "@/Form";
import UnderlinedLink from "@/UnderlinedLink";
import "twin.macro";

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        wp {
          esquireAcf {
            siteSettings {
              formAction
            }
          }
          acfOptionsFooter {
            footer {
              title: footerTitle
              supertext: footerSupertext
              menus {
                title
                menu {
                  link {
                    url
                    title
                  }
                }
              }
              footerLogo {
                ...Image
              }
              footerForm {
                type
                width
                label
                name
                id
                required
              }
              submitButton
            }
          }
        }
      }
    `}
    render={(data) => {
      const { footer: fields } = data.wp.acfOptionsFooter;
      const { formAction } = data.wp.esquireAcf.siteSettings;
      return (
        <Section>
          <Title title={fields.title} supertext={fields.supertext} white />
          <Form
            form={fields.footerForm}
            submit={fields.submitButton}
            action={formAction}
          />
          <Menus>
            <GatsbyImage
              className="logo"
              image={getImage(fields.footerLogo.localFile)}
              alt={fields.footerLogo.altText}
            />
            {fields.menus.map((menu, i) => (
              <Menu key={i}>
                <MenuTitle>{menu.title}</MenuTitle>
                {menu.menu.map(({ link }, j) => (
                  <UnderlinedLink to={link.url} key={j} tw="my-2">
                    {link.title}
                  </UnderlinedLink>
                ))}
              </Menu>
            ))}
          </Menus>
          <Copyright>
            {`Copyright ${new Date().getFullYear()}`}
            <span>|</span>Esquire Video
          </Copyright>
        </Section>
      );
    }}
  />
);

export default Footer;
