import React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

const SEO = ({ seo }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  // Fix yoast schema when it is bogus
  if (seo?.schema?.raw) {
    try {
      const schema = JSON.parse(seo.schema.raw);
      const breadcrumbs = schema["@graph"].find(
        (obj) => obj["@type"] === "BreadcrumbList"
      );
      if (breadcrumbs) {
        breadcrumbs.itemListElement.forEach((el) => {
          if (el.item.name === undefined) el.item.name = seo.title;
        });
      }
      seo.schema.raw = JSON.stringify(schema);
    } catch (e) {
      console.error(`Error fixing Yoast schema for page ${seo.title}: `, e);
    }
  }
  return (
    <Helmet
      title={seo?.title || site.siteMetadata.title}
      meta={[
        // {
        //   name: "robots",
        //   content: `${seo?.metaRobotsNoindex}, ${seo?.metaRobotsNofollow}`,
        // },
        {
          name: "robots",
          content: `noindex, nofollow`,
        },
        {
          name: "og:title",
          content: seo?.opengraphTitle || site.siteMetadata.title,
        },
        { name: "og:description", content: seo.opengraphDescription },
        { name: "description", content: seo.metaDesc },
        { name: "og:type", content: seo.opengraphType },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: seo.title },
        { name: "twitter:description", content: seo.metaDesc },
      ]}
    >
      {seo?.schema?.raw && (
        <script type="application/ld+json">{seo.schema.raw}</script>
      )}
    </Helmet>
  );
};

export default SEO;
