import { cloudinaryURL, hostname, pageBuilderSlug } from "#/constants";
import { siteMetadata } from "../gatsby-config";

/**
 *
 * Gets a local file with cloudinary URL attached,
 * or if in local environment, just the local file's
 * URL.
 *
 * @param {string} url  The relative path to the image
 *
 * @returns {string}    The absolute path to the image
 */
export function getImage(url) {
  if (typeof url !== "string") throw `URL must be a string. Got ${typeof url}`;
  if (url.trim() === "") throw "URL cannot be empty.";
  return cloudinaryURL + url.trim();
}

/**
 *
 * Gets a relative URL from an absolute URL.
 * e.g: https://www.url.com/test -> /test
 *
 * @param {string} url  The absolute URL
 *
 * @returns {string}    A relative URL
 */
export function getRelativeURL(url) {
  if (typeof url !== "string") throw `URL must be a string. Got ${typeof url}`;
  if (url.trim() === "") throw "URL cannot be empty.";

  if (url.includes(siteMetadata.domain))
    url = "/" + url.replace(/^(?:\/\/|[^/]+)*\//, "");

  if (url.substr(-1) !== "/") url += "/";

  return url;
}

/**
 *
 * Gets a local file with the hostname attached in
 * production environments.
 *
 * @param {string} url  The relative path to the asset
 *
 * @returns {string}    The full path to the asset
 */
export function getAsset(url) {
  if (typeof url !== "string") throw "URL must be a string.";
  if (url.trim() === "") throw "URL cannot be empty.";
  return process.env.NODE_ENV !== "development"
    ? hostname + url
    : "http://localhost:8000" + url;
}

/**
 * Converts a flat menu that is returned by graphql
 * into a hierarchical menu that is expected from WordPress.
 *
 * @param {Array}   data  The flat menu to be converted
 * @param {object}  info  idKey, parnetKey, and childrenKey
 *
 * @returns {Array}       The hierarchical conversion of
 *                        the flat menu.
 */
export function flatMenuToHierarchical(
  data = [],
  { idKey = "key", parentKey = "parentId", childrenKey = "children" } = {}
) {
  const tree = [];
  const childrenOf = {};
  data.forEach((item) => {
    const newItem = { ...item };
    const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;
    childrenOf[id] = childrenOf[id] || [];
    newItem[childrenKey] = childrenOf[id];
    parentId
      ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
      : tree.push(newItem);
  });
  return tree;
}

/**
 * Gets the post's number from the list of posts.
 * NOTE: Could potentially dramatically increase build times.
 * Runtime: θ(n^2)
 *
 * @param {object} post The post object to be found. NOTE: Must contain the post's id.
 * @param {bool} human  If true, the human readable number will be returned.
 *                      If false, the zero-based index will be returned.
 *
 * @returns {number}    The number of the post, in the requested format.
 *                      If the post does not exist, will return NaN.
 */
export function getPostNumber(post, posts, human = true) {
  const { id } = post;
  for (let i = 0; i < posts.length; i++)
    if (posts[i].id === id) return i + human;
  return NaN;
}

/**
 * Removes most HTML tags from a string.
 *
 * @param {string} str  The HTML string
 *
 * @returns {string}    The string with HTML tags removed
 */
export function removeHTML(str) {
  if (typeof str !== "string") throw "Argument must be a string.";
  return str.replace(/<[^>]*>/g, " ");
}

/**
 * Adds a leading zero to a number if it is below 10.
 * Used for stylistic list indexes, like 01/, 02/, etc.
 *
 * @param {number} number The number to add a leading zero to
 * @returns {string}      The number with a leading zero attached,
 *                        or just the number if it was greater than 10
 */
export function leadingZero(number) {
  if (typeof number !== "number") throw "Number must be a number.";
  return number < 10 ? `0${number}` : `${number}`;
}

/**
 * Toggles the scrollbar in the browser by setting the body's
 * max height to 100vh and overflow to hidden.
 */
export function toggleScroll() {
  if (typeof document === "undefined")
    throw `Document was not defined. Are you calling toggleScroll in a browser context?`;
  if (document.body.classList.contains("locked")) {
    document.body.maxHeight = null;
    document.body.style.overflowY = null;
  } else {
    document.body.maxHeight = "100vh";
    document.body.style.overflowY = "hidden";
  }
  document.body.classList.toggle("locked");
}

/**
 * Strips a phone number string of any excessive symbols
 * and returns just the digits. Does not error check the phone number.
 *
 * @param {string} num  A string containing a phone number
 * @returns {string}    The phone number without any symbols.
 *                      eg: (609) 661-4926 => 6096614926
 */
export function transformPhoneNumber(num) {
  if (typeof num !== "string")
    throw `Phone number must be a string, received ${typeof num}`;
  return num.replace(/[-.]/g, "");
}

/**
 * Wraps setTimeout in a promise to allow for asynchronous functions
 * to await them, effectively adding a wait statement.
 *
 * @param {number} ms Number of milliseconds to wait for.
 * @returns {Promise} A promise that is resolved after the given amount of milliseconds.
 */
export function delay(ms) {
  if (typeof ms != "number") throw `Time to delay by must be a number.`;
  if (ms < 0) throw `Cannot wait for ${ms}ms, must be non-negative.`;
  return new Promise((resolve) => setTimeout(resolve, ms));
}
