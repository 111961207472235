import React from "react";
import tw, { styled } from "twin.macro";

const styles = () => [
  tw`text-base leading-normal sm:text-lg font-mont text-text`,
];
const Content = ({ article, children, ...rest }) => {
  const Component = article ? styled.article(styles) : styled.p(styles);
  return <Component {...rest} dangerouslySetInnerHTML={{ __html: children }} />;
};

export default Content;
