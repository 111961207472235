import React from "react";
import tw, { styled, css } from "twin.macro";

const InputStyles = styled.input(({ valid }) => [
  tw`flex w-full px-4 py-4 transition-colors duration-500 ease-in-out border-2 border-transparent border-solid sm:py-6`,
  css`
    background-color: #343434;
  `,
  valid === false && tw`border-red-600`,
  valid === true && tw`border-primary`,
]);
const TextArea = styled.textarea(({ valid }) => [
  tw`flex w-full px-4 py-6 transition-colors duration-500 ease-in-out border-2 border-transparent border-solid`,
  css`
    background-color: #343434;
    min-height: 300px;
  `,
  valid === false && tw`border-red-600`,
  valid === true && tw`border-primary`,
]);

const Label = tw.label`text-sm sm:text-base font-bold mb-4`;

const Error = styled.p(({ active }) => [
  tw`mt-2 text-sm font-bold text-red-600 transition-all duration-500 ease-in-out transform translate-y-full opacity-0 sm:text-base`,
  active && tw`translate-y-0 opacity-100`,
]);

const Input = ({ label, id, error, valid, type, ...rest }) => (
  <>
    <Label htmlFor={id}>{label}</Label>
    {type === "textarea" ? (
      <TextArea id={id} {...rest} valid={valid} />
    ) : (
      <InputStyles {...rest} id={id} type={type} valid={valid} />
    )}
    <Error active={valid === false}>{error ? error : "No error."}</Error>
  </>
);

export default Input;
